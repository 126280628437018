





































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { SeminarElement, Submission } from "@/core/models";
import SubmissionFooter from "./SubmissionFooter.vue";

@Component({ components: { SubmissionFooter } })
export default class CategorizeSubmission extends Vue {
  @Prop({ default: () => undefined }) element?: SeminarElement;
  @Prop({ default: () => undefined }) submission?: Submission;

  categories: string[] = [];
  solutions: string[][] = [];
  answers: string[][] = [];
  onChange() {
    if (
      this.element?.type !== "categorize" ||
      this.submission?.type !== "categorize"
    )
      return;
    this.categories = this.element.categories;
    this.solutions = this.element.answers;
    this.answers = (this.submission as any).answers;
  }
  created() {
    this.onChange();
  }

  @Watch("element")
  elementChanged() {
    if (!this.element) return;
    this.onChange();
  }

  isCorrect(i: number, j: number) {
    const phrase = this.answers[i][j];
    return this.solutions[i].includes(phrase);
  }

  correctCategory(i: number, j: number) {
    if (this.element?.type !== "categorize") return "<<unknown>>";
    const phrase = this.answers[i][j];
    for (let ci = 0; ci < this.element.answers.length; ci++)
      if (this.element.answers[ci].some(x => x === phrase))
        return this.element.categories[ci];
    return "<<unknown>>";
  }
}
