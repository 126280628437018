



























































































































































































































































































































































































































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop, Watch } from "vue-property-decorator";

import { typeName } from "@/core/utils/seminars";
import {
  InvitationData,
  Seminar,
  Invitation,
  SeminarUser,
  Submission,
  SemIdUId,
} from "@/core/models";
import api from "@/core/utils/api";

import EmailsInput from "@/components/common/EmailsInput.vue";
import VideoPlayer from "@/components/common/VideoPlayer.vue";
import VideoRecorder from "@/components/common/VideoRecorder.vue";
import GapTextSubmission from "../components/GapTextSubmission.vue";
import RephraseSubmission from "../components/RephraseSubmission.vue";
import CategorizeSubmission from "../components/CategorizeSubmission.vue";
import VideoSubmission from "../components/VideoSubmission.vue";

type ItemName = { email: string; username: string };
@Component({
  components: {
    EmailsInput,
    VideoPlayer,
    VideoRecorder,
    GapTextSubmission,
    RephraseSubmission,
    CategorizeSubmission,
    VideoSubmission,
  },
})
export default class SeminarParticipants extends Vue {
  @Prop({ default: () => undefined }) seminar?: Seminar;

  // invitations
  @Getter("invitations/sending") sending!: boolean;
  @Action("invitations/send")
  inviteUsers!: (d: InvitationData) => Promise<any[]>;

  // seminars
  @Action("seminars/addSubmission") addSubmission!: (s: Submission) => void;
  @Action("seminars/clearAllSubmissions")
  clearAllSubmissions!: (i: number) => Promise<void>;
  @Action("seminars/clearUserSubmissions")
  clearUserSubmissions!: (d: SemIdUId) => Promise<void>;
  @Action("seminars/removeUser")
  removeUser!: (d: SemIdUId) => Promise<void>;
  @Action("seminars/removeAllUsers")
  removeAllUsers!: (i: number) => Promise<void>;

  // utils
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  typeName = typeName;

  // users controller
  userDialog = false;
  user: SeminarUser | null = null;
  get userSubmissions() {
    return this.seminar?.allSubmissions?.filter(
      x => x.userId === this.user?.id,
    );
  }
  get submissionElements() {
    return this.seminar?.allSubmissions
      ?.filter(x => x.userId === this.user?.id)
      .map(x => this.seminar?.elements?.find(y => y.id === x.elementId));
  }
  openUserDialog(id: string) {
    const found = this.allUsers.find(x => x.id === id);
    if (!found) {
      this.displaySnackbar(
        this.$t("seminars.admin.participants.userNotFound").toString(),
      );
      return;
    }
    this.userDialog = true;
    this.user = found;
  }
  closeUserDialog() {
    this.userDialog = false;
    this.user = null;
  }

  // filter controller
  usersLoading = false;
  filtersDialog = false;
  progressFilter = 0;
  currentBlockFilter = 0;
  currentElementFilter = 0;
  filteredUsers: SeminarUser[] = [];
  allUsers: SeminarUser[] = [];
  clearFilters() {
    this.filtersDialog = false;
    this.progressFilter = 0;
    this.currentBlockFilter = 0;
    this.currentElementFilter = 0;
    this.filteredUsers = this.allUsers;
  }
  applyFilters() {
    this.filtersDialog = false;
    if (this.progressFilter !== 0)
      this.filteredUsers = this.filteredUsers.filter(
        x => x.progress >= this.progressFilter,
      );
    if (this.currentBlockFilter !== null)
      this.filteredUsers = this.filteredUsers.filter(
        x => x.currentBlock >= this.currentBlockFilter,
      );
    if (this.currentElementFilter !== null)
      this.filteredUsers = this.filteredUsers.filter(
        x => x.currentElement >= this.currentElementFilter,
      );
  }

  // search controller
  search = "";

  // table with users controller
  get headers() {
    return [
      {
        text: this.$t("seminars.admin.participants.name").toString(),
        value: "name",
        sortable: true,
        sort: (a: ItemName, b: ItemName) =>
          b.email + b.username > a + a.username,
      },
      {
        text: this.$t("seminars.admin.participants.progress").toString(),
        value: "progress",
        sortable: true,
      },
      // {
      //   text: this.$t("seminars.admin.participants.pos").toString(),
      //   value: "position",
      //   sortable: true,
      //   sort: (a: [number, number], b: [number, number]) =>
      //     b[0] + b[1] - (a[0] + a[1]),
      // },
      {
        text: this.$t("seminars.edit.actions").toString(),
        value: "actions",
        sortable: false,
      },
    ];
  }

  get items() {
    const mapper = (x: SeminarUser) => ({
      id: x.id,
      progress: x.progress,
      name: { email: x.email, username: x.username },
      position: [x.currentBlock, x.currentElement],
    });

    let list = this.filteredUsers;
    if (this.search.trim().length > 0)
      list = list.filter(x => `${x.email};${x.username}`.includes(this.search));
    return list.map(mapper);
  }

  async getAllUsers() {
    const id = this.seminar?.id;
    if (!id) return;

    this.usersLoading = true;
    try {
      this.allUsers = await api.get(`/api/Seminar/Users/${id}`);
      this.clearFilters();
    } catch (error) {
      console.log(error);
      this.displaySnackbar(
        this.$t("seminars.admin.participants.fetchFail").toString(),
      );
    }
    this.usersLoading = false;
  }

  // sent invitations controller
  revoking = false;
  revokedId = 0;
  sentInvitationsLoading = false;
  sentInvitations: Invitation[] = [];
  async getSentInvitations() {
    const id = this.seminar?.id;
    if (!id) return;
    this.sentInvitationsLoading = true;
    try {
      const end = `/api/Invitations/SentForSeminar/${id}`;
      const invs = (await api.get(end)) as Invitation[];
      this.sentInvitations = invs;
    } catch (error) {
      console.log(error);
      const msg = this.$t("seminars.admin.invitations.fetchFail").toString();
      this.displaySnackbar(msg);
    }
    this.sentInvitationsLoading = false;
  }
  async revoke(inv: Invitation) {
    const id = inv.id;
    this.revokedId = id;
    this.revoking = true;
    try {
      await api.delete(`/api/Invitations/Revoke/${id}`);
      this.sentInvitations = this.sentInvitations.filter(x => x.id !== id);
    } catch (error) {
      console.log(error);
      this.displaySnackbar(
        this.$t("seminars.admin.invitations.revokeFail").toString(),
      );
    }
    this.revokedId = 0;
    this.revoking = false;
  }

  // refresh the above two
  refresh() {
    this.getSentInvitations();
    this.getAllUsers();
  }

  // invite users controller
  inviteDialog = false;
  emails: string[] = [];
  nextUserEmail: string | null = null;
  openInviteUsers() {
    this.inviteDialog = true;
  }
  closeInviteDialog() {
    this.inviteDialog = false;
  }
  async confirmInvite() {
    if (!this.seminar) return;
    this.inviteDialog = false;

    // check currently typed email (tab not pressed yet)
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
    if (
      this.nextUserEmail !== null &&
      this.nextUserEmail.trim().length > 3 &&
      !pattern.test(this.nextUserEmail)
    ) {
      this.displaySnackbar(
        this.$t("seminars.admin.invitations.invalidEmail").toString(),
      );
      return;
    }

    // exclude already invited users
    const emails = !this.nextUserEmail?.length
      ? this.emails
      : [...this.emails, this.nextUserEmail];
    const userEmails = emails.filter(
      x => !this.sentInvitations.map(y => y.email).includes(x),
    );
    if (userEmails.length === 0)
      return this.displaySnackbar("No new users selected");

    // call api
    const invs = (await this.inviteUsers({
      seminarId: this.seminar.id,
      userEmails,
    })) as Invitation[];
    this.sentInvitations = [...this.sentInvitations, ...invs];
  }

  // owner giving feedback controller
  feedbackDialog = false;
  uploading = false;
  selectedVideo: Submission | null = null;
  get askingForFeedback() {
    return this.seminar?.allSubmissions
      ?.filter(x => {
        if (x.type === "video_presentation" || x.type === "give_feedback")
          return x.askForFeedback;
        return false;
      })
      .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
  }
  getUser(id: string) {
    return this.seminar?.users?.find(x => x.id === id)?.username;
  }
  getElement(id: number) {
    return this.seminar?.elements?.find(x => x.id === id)?.title;
  }
  selectVideo(item: Submission) {
    this.selectedVideo = item;
    this.feedbackDialog = true;
  }
  closeFeedbackDialog() {
    this.feedbackDialog = false;
    this.selectedVideo = null;
  }
  get givenFeedback() {
    if (!this.selectedVideo) return false;
    return this.seminar?.submissions?.find(x => {
      if (x.type === "give_feedback")
        return x.feedbackForSubmission === this.selectedVideo?.id;
      return false;
    });
  }
  async upload({ blob }: { blob: Blob }) {
    if (!this.selectedVideo || !this.seminar) return;
    this.uploading = true;
    try {
      const data = new FormData();
      data.append("elementId", this.selectedVideo?.elementId.toString());
      data.append("feedbackFor", this.selectedVideo.userId);
      data.append("feedbackForSubmission", this.selectedVideo.id.toString());
      data.append("isShared", new Boolean(false).toString());
      data.append("videoFile", blob);
      data.append("seminarId", this.seminar.id.toString());
      data.append("askForFeedback", new Boolean(false).toString());
      const submission = (await api.post(
        "/api/Submissions/GiveFeedback",
        data,
        { headers: { "Content-Type": "multipart/form-data" } },
      )) as Submission;
      this.addSubmission(submission);
    } catch (error) {
      console.log(error);
      this.displaySnackbar(error);
    }
    this.uploading = false;
  }

  // check if seminar is changed
  @Watch("seminar", { immediate: true })
  seminarChanged() {
    if (!this.seminar?.id) return;
    this.getSentInvitations();
    this.filteredUsers = this.seminar.users || [];
    this.allUsers = this.seminar.users || [];
  }

  // remove user
  removeDialog = false;
  removing = false;
  removeCancel() {
    this.removeDialog = false;
    this.user = null;
  }
  async removeConfirm() {
    const seminarId = this.seminar?.id;
    if (!seminarId) return;

    this.removing = true;
    try {
      const userId = this.user?.id;
      userId
        ? await this.removeUser({ seminarId, userId })
        : await this.removeAllUsers(seminarId);
      this.removeCancel();
    } catch (error) {
      console.log(error);
      this.displaySnackbar("Could not remove this user");
    }
    this.removing = false;
  }
  openRemoveUser() {
    this.userDialog = false;
    this.removeDialog = true;
  }

  // clearing submissions
  clearDialog = false;
  clearingAll = false;
  async clearAll() {
    const id = this.seminar?.id;
    if (!id) return;
    this.clearDialog = false;
    this.clearingAll = true;
    await this.clearAllSubmissions(id);
    this.clearingAll = false;
  }

  selectUserForClearing() {
    this.userDialog = false;
    this.clearDialog = true;
  }
  closeClearDialog() {
    this.clearDialog = false;
    this.clearDialog = false;
    this.clearingAll = false;
    this.user = null;
  }
  async clearForUser() {
    // seminar dne
    const id = this.seminar?.id;
    if (!id) return;

    // user dne
    const uid = this.user?.id;
    if (!uid || !this.seminar?.users?.some(x => x.id === uid)) return;

    // send request
    this.clearDialog = false;
    this.clearingAll = true;
    await this.clearUserSubmissions({ seminarId: id, userId: uid });
    this.clearingAll = false;
    this.user = null;
  }
}
